import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1 className="small-padding">What information do we collect?</h1>
          <p className="small-padding">
            We collect information from you when you register on our site,
            subscribe to our newsletter or fill out a form. When ordering or
            registering on our site, as appropriate, you may be asked to enter
            your: name, e-mail address or phone number. You may, however, visit
            our site anonymously.
          </p>
          <hr />
          <h1 className="small-padding">
            What do we use your information for?
          </h1>
          <p className="small-padding">
            Any of the information we collect from you may be used in one of the
            following ways: To improve our website (we continually strive to
            improve our website offerings based on the information and feedback
            we receive from you). To administer a contest, promotion, survey or
            other site feature. To send periodic emails The email address you
            provide for order processing, will only be used to send you
            information and updates pertaining to your order. If you decide to
            opt-in to our mailing list, you will receive emails that may include
            company news, updates, related product or service information, etc.
          </p>
          <hr />
          <h1 className="small-padding">Do we use cookies?</h1>
          <p className="small-padding">
            The Miami-Dade Beacon Council website uses cookies, tracking pixels
            and related technologies. Cookies are small data files that are
            served by our platform and stored on your device. Our site uses
            cookies dropped by us or third parties for a variety of purposes
            including to operate and personalize the website. Also, cookies may
            also be used to track how you use the site to target ads to you on
            other websites.
          </p>
          <hr />
          <h1 className="small-padding">Your Choices and Opting-Out</h1>
          <p className="small-padding">
            We recognize how important your online privacy is to you, so we
            offer the following options for controlling the targeted ads you
            receive and how we use your data:
          </p>
          <ul>
            <li>
              You can opt out of receiving targeted ads served by AdRoll or on
              our behalf by clicking on the blue icon that typically appears in
              the corner of the ads we serve or by clicking
              <a href="https://app.adroll.com/optout/safari" target="_blank">
                here.
              </a>
              . Please note that, if you delete your cookies or upgrade your
              browser after having opted out, you will need to opt out again.
              Further, if you use multiple browsers or devices you will need to
              execute this opt out on each browser or device. If you opt-out we
              may collect some data about your online activity for operational
              purposes (such as fraud prevention) but it won’t be used by us for
              the purpose of targeting ads to you.
            </li>
            <li>
              AdRoll is also a member of the Network Advertising Initiative
              (NAI) and adheres to the NAI Codes of Conduct. You may use the NAI
              opt out tool
              <a
                href="https://optout.networkadvertising.org/?c=1"
                target="_blank"
              >
                here
              </a>
              , which will allow you to opt out of seeing targeted ads from
              AdRoll and from other NAI approved member companies.
            </li>
          </ul>
          <hr />
          <h1 className="small-padding">
            Do we disclose any information to outside parties?
          </h1>
          <p className="small-padding">
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses.
          </p>
          <hr />
          <h1 className="small-padding">Third party links</h1>
          <p className="small-padding">
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <hr />
          <h1 className="small-padding">
            Children’s Online Privacy Protection Act Compliance
          </h1>
          <p className="small-padding">
            We are in compliance with the requirements of COPPA (Children’s
            Online Privacy Protection Act), we do not collect any information
            from anyone under 13 years of age. Our website, products and
            services are all directed to people who are at least 13 years old or
            older.
          </p>
          <hr />
          <h1 className="small-padding">Online Privacy Policy Only</h1>
          <p className="small-padding">
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </p>
          <hr />
          <h1 className="small-padding">Terms and Conditions</h1>
          <p className="small-padding">
            Please also visit our Terms and Conditions section establishing the
            use, disclaimers, and limitations of liability governing the use of
            our website at
            <a
              href="https://www.beaconcouncil.com/terms-conditions/"
              target="_blank"
            >
              Terms and Conditions.
            </a>
          </p>
          <hr />
          <h1 className="small-padding">Your Consent</h1>
          <p className="small-padding">
            By using our site, you consent to our privacy policy.
          </p>
          <hr />
          <h1 className="small-padding">Changes to our Privacy Policy</h1>
          <p className="small-padding">
            If we decide to change our privacy policy, we will post those
            changes on this page, and/or update the Privacy Policy modification
            date below. This policy was last modified on 6-19-09
          </p>
          <hr />
          <h1 className="small-padding">Contacting Us</h1>
          <p className="small-padding">
            If there are any questions regarding this privacy policy you may
            contact us using the information below. www.BeaconCouncil.com 80 SW
            8th Street Suite 2400 Miami, FL 33130 info@beaconcouncil.com
          </p>
          <Link className="full-cta" to="/">
            Go back to the homepage
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
